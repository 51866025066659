export const FETCH_VIRTUAL_TOUR_LIST_SUCCESS = 'fetch_virtual_tour_list_success'
export const FETCH_VIRTUAL_TOUR_LIST_ERROR = 'FETCH_VIRTUAL_TOUR_LIST_ERROR'
export const VIRTUAL_TOURS_CHILD_ADDED = 'virtual_tours_child_added'
export const VIRTUAL_TOURS_CHILD_UPDATED = 'virtual_tours_child_updated'
export const VIRTUAL_TOURS_CHILD_REMOVED = 'virtual_tours_child_removed'
export const FETCH_SINGLE_TOUR_LIST_SUCCESS = 'FETCH_SINGLE_TOUR_LIST_SUCCESS'
export const FETCH_SCENE_LIST_SUCCESS = 'FETCH_SCENE_LIST_SUCCESS'
export const FETCH_SCENE_LIST_ERROR = 'FETCH_SCENE_LIST_ERROR'
export const SCENE_CHILD_ADDED = 'SCENE_CHILD_ADDED'
export const SCENE_CHILD_UPDATED = 'SCENE_CHILD_UPDATED'
export const SCENE_CHILD_REMOVED = 'SCENE_CHILD_REMOVED'
